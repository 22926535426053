/* global $, Dropzone, App, Util, SWAL, NextAction, Bloodhound, Inputmask, CKEDITOR, Toastr, Cleave */
/* global autosize */
/* global window, location */
class Plugin {
	constructor(element) {
		this.element = element ? element : undefined;
		if (!this.element) return { 'error': 'No element' };

		if ($.fn.dropzone) Dropzone.autoDiscover = false;

		if ($.fn.editableutils) {
			$.fn.editableutils.setCursorPosition = function (elem, pos) {
				if (elem.setSelectionRange && elem.type !== 'number') {
					elem.setSelectionRange(pos, pos);
				} else if (elem.createTextRange) {
					const range = elem.createTextRange();
					range.collapse(true);
					range.moveEnd('character', pos);
					range.moveStart('character', pos);
					range.select();
				}
			};
		}

		if (!this.init()) {
			return { 'error': 'Plugin init failed' };
		}
	}

	init() {
		const cleave = this.numDelimeter();
		const datepicker = this.datepicker();
		const dropzone = this.dropzone();
		const typeahead = this.typeahead();
		const bsEditable = this.bsEditable();
		const imask = this.imask();
		const ckeditor = this.ckeditor();
		const select2 = this.select2();
		const button = this.button();
		const card = this.element.card();
		const btns = this.element.unibutton().initAll();
		const gallery = this.gallery();
		const generateCSS = this.generateCSS();
		autosize($('textarea.autoExpand', this.element));
		if (cleave || datepicker || dropzone || typeahead || bsEditable || imask || ckeditor || select2 || card || btns || button || gallery) {
			// console.log(`Plugins initialized. datepicker: ${datepicker}; dropzone: ${dropzone}; typeahead: ${typeahead}; bsEditable: ${bsEditable}; imask: ${imask}; ckeditor: ${ckeditor}; select2: ${select2}; card: ${card}; buttons: ${btns}; button: ${button}`);
			return 1;
		} else {
			return { 'error': 'No plugins initialized' };
			// return 0;
		}
	}

	generateCSS() {
		const createStyle = (css) => {
			const head = document.head || document.getElementsByTagName('head')[0];
			const style = document.createElement('style');
			const removeOld = () => $('#generatedCSS').remove();

			style.type = 'text/css';
			style.id = 'generatedCSS';
			style.styleSheet
				? style.styleSheet.cssText = css
				: style.appendChild(document.createTextNode(css));

			removeOld();
			head.appendChild(style);
		};

		const generation = (array, elem) => {
			const rgxp = new RegExp(`.*${elem}-(\\d+?)(\\s|$).*`);
			const isChild = elem === 'max-width' ? '*' : '';
			const css = `.${elem}-$1 ${isChild} { ${elem}: $1px !important }\n`;
			return array
				.map(e => e.className)
				.filter((v, i, a) => a.indexOf(v) === i)
				.map(e => e.replace(rgxp, css))
				.reduce((p, n) => p + n, '');
		};

		const arrayFor = (string) => Array.from($(`[class^=${string}]`));
		const cssFor = (string) => generation(arrayFor(string), string);
		const cssCombine = (array) => array.reduce((p, n) => p + cssFor(n), '');

		const result = cssCombine(['max-width', 'padding-top']);

		if (result) { createStyle(result); }
	}

	button() {
		$('.ajax_row_btn', this.element).on('click', function (event) {
			event.preventDefault();
			const btn = $(this);
			const tr = btn.closest('tr');
			const table = btn.closest('table');

			if (tr.data('child_shown')) {
				const isSameTable = tr.next().data('caller') === btn.get(0);
				tr.next().remove();
				tr.data('child_shown', false);
				if (!isSameTable) {
					createTable(btn, tr, table);
				}
			} else {
				createTable(btn, tr, table);
			}
			function createTable(btn, tr, table) {
				App.blockUI({ boxed: true, target: table, message: 'Загрузка...' });
				$.ajax({
					type: 'post',
					data: btn.data('post'),
					success: function (data) {
						tr.after('<tr></tr>');
						tr.next().append(`<td colspan="${tr.find('td').length}"></td>`);
						tr.next().data('caller', btn.get(0));
						const td = tr.next().find('td').eq(0);
						td.css('background-color', '#1fae66');
						td.css('padding', '2rem');
						td.html(data).promise().done(function () {
							// console.log('Button ajax_row after html insert');
							Util.initialize_all($(tr.next()));
							App.unblockUI(table);
							tr.data('child_shown', true);
						});
					},
					error: function () {
						App.unblockUI(table);
					}
				});

			}
		});

		$('.button_submit', this.element).on('click', function (event) {
			event.preventDefault();
			const btn = $(this);
			$.ajax({
				url: Util.blackbox('/app/dynproc/'),
				type: 'post',
				data: Util.serialize(btn.closest('form').data('post'), btn.data('post')) + '&' + btn.closest('form').serialize(),
				success: function (data) {
					if (data.status) {
						new NextAction(data.next_action, btn);
					} else {
						SWAL.error(data);
					}
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					el.cardinput().handleError(XMLHttpRequest, textStatus, errorThrown);
				}
			});
		});

		// При нажатии кнопки "Обновить"
		$('.techproc_refresh', this.element).on('click',()=>{
			location.reload();
		});

		$('.modal_btn', this.element).on('click', function (event) {
			event.preventDefault();
			let btn = $(this);
			let modal = $('#ajax-modal');
			let caller = btn.closest('tr').length ? btn.closest('tr') : btn.closest('.table-content');

			modalAJAX.call(this);

			function modalAJAX() {
				$('body').modalmanager('loading');
				if (modal.css('display') === 'block') {
					modal.modal('hide');
				}
				modal.data('modal-caller', caller);
				modal.load(Util.blackbox('/app/modal/'), btn.data('post'), function (data) {
					if (/^[\],:{}\s]*$/.test(data.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
						var jdata = $.parseJSON(data);
						if (jdata.status) {
							modal.modal();
						} else {
							$('.modal-scrollable').remove();
							$('.modal-backdrop').remove();
							SWAL.show(jdata, 'error');
						}
					} else {
						modal.modal();
						modal.plugin();
						if (typeof cryptoManager !== 'undefined') { cryptoManager.buttonInit(); }
					}
				});
			}
		});

		$('.call_js_btn:not(.confirm)', this.element).on('click', function (event) {
			event.preventDefault();
			event.stopPropagation(); event.stopImmediatePropagation();
			if ($(this).hasClass('tep_comment')) {
				const remark = $(this).closest('.row').find('div.remark').eq(0);
				if (remark.is(':hidden')) {
					remark.slideDown();
				} else {
					remark.slideUp();
				}
			} else if ($(this).hasClass('change_stage')) {
				var btn = $(this);
				$.ajax({
					url: Util.blackbox('/app/dynproc/'),
					processData: true,
					type: 'post',
					dataType: 'json',
					data: btn.data().post,
					success: function (data) {
						if (data.status) {
							location.reload();
						} else {
							SWAL.error(data);
						}
					}
				});
			} else if ($(this).hasClass('docs_to_excel')) {
				var params = {};
				var $form = $('form.filters');
				$('form.filters .filtergroup').each(function () {
					if ($(this).find('input.date-range').length) {
						if ($(this).find('input.date-range.start').val() || $(this).find('input.date-range.end').val()) {
							params[$(this).find('input.date-range').attr('name')] = $(this).find('input.date-range.start').val() + ' - ' + $(this).find('input.date-range.end').val();
						}
					} else if ($(this).find('input:checked').length) {
						params[$(this).find('input:checked').attr('name')] = $(this).find('input:checked').val();
					} else if ($(this).find('.select2').length && $(this).find('.select2').val()) {
						params[$(this).find('.select2').attr('name')] = $(this).find('.select2').val();
					} else if ($(this).find('input.filter_number').length) {
						params[$(this).find('input.filter_number').attr('name')] = $(this).find('input.filter_number.start').val() + ' - ' + $(this).find('input.filter_number.end').val();
					} else if ($(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.date-range)').length) {
						params[$(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.date-range)').attr('name')] = $(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.date-range)').val();
					}
				});
				if ($form.find('input[name=search_context]').length && $form.find('input[name=search_context]').val()) {
					params.search_context = $form.find('input[name=search_context]').val();
				}
				if ($form.find('input[name=itid]').length && $form.find('input[name=itid]').val()) {
					params.itid = $form.find('input[name=itid]').val();
				}
				if ($form.find('input[name=wmode]').length && $form.find('input[name=wmode]').val()) {
					params.wmode = $form.find('input[name=wmode]').val();
				}
				window.location = 'xlsx/' + '?' + $.param(params);
			} else if ($(this).hasClass('tabletoXLS')) {
				if (document.querySelector('.xls-print')) {
					Util.tableToXLS('.xls-print');
				} else {
					SWAL.error('Не найдена таблица');
				}

			}
		});

		if ($.fn.confirmation) {
			let status_req = false;
			$('.confirm', this.element).confirmation({
				rootSelector: '.confirm',
				btnOkLabel: 'Да',
				btnOkIcon: 'fa fa-check',
				btnOkClass: 'green',
				btnCancelLabel: 'Нет',
				btnCancelIcon: 'fa fa-times',
				btnCancelClass: 'red'
			});
			Array.from($('[tooltip]', this.element)).forEach(e => {
				if (e.getAttribute('tooltip')) {
					e.setAttribute('title', e.getAttribute('tooltip'));
				}
			});
			$('.confirm', this.element).on('confirmed.bs.confirmation', function (event) {
				const btn = $(this);
				event.stopPropagation();
				event.stopImmediatePropagation();
				if (btn.hasClass("ajax_one_req")) {
					if(!status_req) {
						status_req = true;
						$.ajax({
							url: Util.blackbox('/app/dynproc/'),
							processData: true,
							type: 'post',
							dataType: 'json',
							data: Util.serialize(btn.data('post')),
							success: function (data) {
								status_req = false;
								if (data.status) {
									new NextAction(data.next_action, btn);
								} else {
									SWAL.error(data);
								}
							}
						});
					}
				} else {
					$.ajax({
						url: Util.blackbox('/app/dynproc/'),
						processData: true,
						type: 'post',
						dataType: 'json',
						data: Util.serialize(btn.data('post')),
						success: function (data) {
							if (data.status) {
								new NextAction(data.next_action, btn);
							} else {
								SWAL.error(data);
							}
						}
					});
				}
			});
		}

		return this;
	}

	numDelimeter() {
		const fAll = (q) => document.querySelectorAll(q);
		const numDelimSpace = (s) => {
			return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		};

		/**
		 * Add delimeters to span
		 */
		Array.from(fAll('span.num-format')).forEach(el => {
			if (el.textContent) { el.textContent = numDelimSpace(el.textContent); }
		});

		/**
		 * Enable plugin on all inputs
		 */
		Array.from(fAll('input[type="text"].num-format')).forEach(el => {
			new Cleave(el, {
				numeral: true,
				delimiter: ' '
			});
		});

		return this;
	}

	datepicker() {
		if (!$.fn.datepicker) return 0;
		$('input.date-range, input.date-picker', this.element).datepicker({
			todayHighlight: true,
			rtl: App.isRTL(),
			language: 'ru',
			orientation: 'bottom',
			autoclose: true,
			format: 'dd.mm.yyyy',
			assumeNearbyYear: true,
			startDate: '01.01.1800'
		});
		return 1;
	}

	dropzone() {
		if (!$.fn.dropzone) return 0;
		for (let dz of $('.dropzone-custom', this.element)) {			
			if (dz.dropzone) {
				dz.dropzone.on("success", function(file, response) {
					if (file.previewElement) {
						file.previewElement.classList.add('dz-success');
						$(file.previewElement).delay(2000).fadeOut('500').promise().done(function () {
							const form = this.closest('.dz-started');
							this.remove();
							if (!form.find('.dz-preview').length) {
								form.removeClass('dz-started');
								form.find('.textProgress').text('');
							}
						});
						$.fn.unibutton().updateFolder($(file.previewElement).closest('.folder'));
					}
				})
			} else {
				$(dz).dropzone({
					method: 'post',
					paramName: 'file',
					parallelUploads: 4,
					maxFilesize: 2048,
					timeout: 0,
					success: (file) => {
						if (file.previewElement) {
							file.previewElement.classList.add('dz-success');
							$(file.previewElement).delay(2000).fadeOut('500').promise().done(function () {
								const form = this.closest('.dz-started');
								this.remove();
								if (!form.find('.dz-preview').length) {
									form.removeClass('dz-started');
									form.find('.textProgress').text('');
								}
							});
							$.fn.unibutton().updateFolder($(file.previewElement).closest('.folder'));
						}
					},
					totaluploadprogress: (progress) => {
						$(dz).find('.textProgress').text(Math.floor(progress) + '%');
					}
				});
			}	
			const dropzone = Dropzone.forElement(dz);
			if (dropzone) {
				dropzone.on('addedfile', function (file) {
					const cancelLink = $('<a class="cancelUpload" href="javascript:undefined;">Отменить</a>');
					cancelLink.appendTo($(file.previewElement).find('.dz-details'));
					cancelLink.click(function (e) {
						e.preventDefault();
						dropzone.cancelUpload(file);
						cancelLink.remove();
					});
				});
			}
		}

		return 1;
	}

	typeahead() {
		if (!$.fn.typeahead) return 0;
		const bloodhound = new Bloodhound({
			datumTokenizer: d => d.tokens,
			queryTokenizer: Bloodhound.tokenizers.whitespace,
			remote: {
				url: Util.blackbox('/app/dynproc/'),
				replace: (url, uriEncodedQuery) => `${url}?q=${encodeURIComponent(uriEncodedQuery)}&typeahead=1&${Util.serialize($('[data-autocomplete=1]').filter(':focus').data('get'))}`
			}
		});

		bloodhound.initialize();

		$('[data-autocomplete=1]', this.element).typeahead(null, {
			name: 'typeahead',
			hint: !App.isRTL(),
			source: bloodhound.ttAdapter()
		});

		$('[data-autocomplete=1]', this.element).closest('.card-input-container').addClass('popup-fix');

		$('.tt-input', this.element).attr('tabindex', 1);

		return 1;
	}

	bsEditable() {
		if (!$.fn.editable) return 0;
		const url = Util.blackbox('/app/dynproc/');
		for (let editable of $('.x-editable', this.element)) {
			$(editable).editable({
				url: url,
				send: 'always',
				source: `${Util.blackbox('/app/dynproc/')}?${Util.serialize($(editable).data('get'))}`,
				sourceCache: false,
				defaultValue: $(editable).data('default-value'),
				inputclass: 'form-control input-medium',
				params: params => {return Util.serializeObject(params, $(editable).data('post')) },
				success: response => response.status ? undefined : response.message
			});
		}
		return 1;
	}

	imask() {
		if (!$.fn.inputmask) return 0;
		for (let im of $('[data-mask]', this.element)) {
			$(im).data('mask') ? Inputmask($(im).data('mask')).mask(im) : undefined;
		}
		return 1;
	}

	ckeditor() {
		if (!$.fn.ckeditor) return 0;
		$('.ckeditor', this.element).ckeditor({
			language: 'ru',
			skin: 'office2013',
			// contentEditable: true,
			// disableAutoInline: true,
			// contenteditable: true,
			toolbar: [
				{ name: 'clipboard', items: ['Undo', 'Redo', '-'] },
				{ name: 'insert', items: ['Table'] },
				{ name: 'styles', items: ['Format', 'Font', 'FontSize'] },
				{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
				{ name: 'colors', items: ['TextColor', 'BGColor'] },
				{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
				{ name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll'] },
				{ name: 'links', items: ['Link', 'Unlink'] },
				{ name: 'document', items: ['Preview', 'Print'] },
				{ name: 'tools', items: ['Maximize'] }
			],
			removePlugins: 'onchange'
		});

		CKEDITOR.on('instanceReady', function (ev) {
			ev.editor.on('maximize', function () {
				$('#sidepanel').show();
			});
		});


		if (Object.keys(CKEDITOR.instances).length) {
			for (let i in CKEDITOR.instances) {
				const ck = CKEDITOR.instances[i];
				ck.on('change', ev => {
					window.clearTimeout(timeoutHandle);
					const el = $(ev.editor.element.$);
					el.cardinput().handleChange();
					timeoutHandle = window.setTimeout(function () {
						el.cardinput().handleProcess();

						$.ajax({
							url: Util.blackbox('/app/dynproc/'),
							type: 'post',
							data: $.param(Util.cleanUpData($.extend({ value: el.val() }, el.data().post))),
							success: function (data) {
								if (Number(data.status) === 1) {
									el.cardinput().handleSave();
								} else {
									el.cardinput().handleError(null, null, data.message);
								}
							},
							error: function (XMLHttpRequest, textStatus, errorThrown) {
								el.cardinput().handleError(XMLHttpRequest, textStatus, errorThrown);
							}
						});
					}, 1000);
				});
			}
		}
		return 1;
	}

	select2() {
		let dropdown=null;
		const language = {
			errorLoading: () => 'Невозможно загрузить результаты',
			inputTooLong: t => `Пожалуйста, введите на ${t.input.length - t.maximum} символ меньше`,
			inputTooShort: t => `Пожалуйста, введите еще хотя бы ${t.minimum - t.input.length} символ`,
			loadingMore: () => 'Загрузка данных...',
			maximumSelected: t => `Вы можете выбрать не более ${t.maximum} элементов`,
			noResults: () => 'Совпадений не найдено',
			searching: () => 'Поиск...'
		};

		const defopts = {
			allowClear: true,
			language: language,
			minimumInputLength: 0,
			/*
				Temporary workaround for current version of select2
				https://github.com/select2/select2/issues/3497
			*/
			// FIX
			placeholder: {
				id: "",
				placeholder: ""
			},
			// END FIX
			escapeMarkup: markup => markup
		};

		const ajaxopts = {
			ajax: {
				url: Util.blackbox('/app/dynproc/'),
				dataType: 'json',
				delay: 1500,
				data: function (params) {
					return Util.serializeObject({ q: params.term }, $(this).data('get'));
				},
				processResults: data => ({
					results: $.map(data, res => ({ id: res.value, text: res.text }))
				}),
				cache: true
			},
		};

		if ($.fn.confirmation) {
			$('.select_confirm', this.element).confirmation({
				rootSelector: '.select_confirm',
				btnOkLabel: 'Да',
				btnOkIcon: 'fa fa-check',
				btnOkClass: 'green',
				btnCancelLabel: 'Нет',
				btnCancelIcon: 'fa fa-times',
				btnCancelClass: 'red',
				trigger: 'manual',
				title: 'Сохранить?'
			});
		}

		$('.select_confirm', this.element).on('confirmed.bs.confirmation', function (event) {
			dropdown();
		});



		function handleDropdown(event, selfConfirm, selfSelect){
			selfConfirm.confirmation("show");
			return function(){
				selfSelect.val(event.params.args.data.id).trigger('change');
			}
		}

		$('.select2', this.element).each(function (el) {
			const options = $(this).data('select2ajax') ? Util.merge(defopts, ajaxopts) : defopts;
			$(this).select2(options); // TODO: FIX SELECT2

			// в случае необходимости подтверждения при выборе значения
			$(this).on('select2:selecting', function (event) {
				const selfConfirm = $(this).parent().siblings('.select_confirm');
				const selfSelect = $(this);
				if(selfConfirm.length) {
					event.preventDefault();
					event.stopPropagation();
					dropdown = handleDropdown(event, selfConfirm, selfSelect);
				}
			});

			// после удаления значения, выпадающее меню закрывается
			$(this).on("select2:unselecting", function(e) {
				$(this).data('state', 'unselected');
			  }).on("select2:open", function(e) {
				if ($(this).data('state') === 'unselected') {
				  $(this).removeData('state');

				  var self = $(this);
				  setTimeout(function() {
					self.select2('close');
				  }, 1);
				}
			  })
		});

		return 1;
	}

	gallery() {
		$('.gallery').each(function() { // the containers for all your galleries
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
			gallery: {
				enabled:true
			}
		});
		});
		return 1;
	}
}

$.fn.plugin = function () {
	const plugin = new Plugin(this);
	if (plugin.error) {
		Toastr.error(plugin.error, 'Plugin initialization error');
		// throw new Error(plugin.error);
	}
	return plugin;
};
